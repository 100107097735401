import { getAccessToken } from "./token";

export const fetcher = async (url: string, params: any) => {
  const res = await fetch(
    "https://2023transilicitana-checkin.threetech.es/api/volunteers/admin" +
      url,
    {
      ...params,
      headers: {
        Authorization: "Bearer " + getAccessToken(),
        Accept: "application/json",
      },
    }
  );
  if (!res.ok) {
    const error: any = new Error("An error occurred while fetching the data.");
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};
