import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  header: {
    display: "flex",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      justifyContent: "space-around",
    },
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default useStyles;
