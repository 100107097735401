import useSWR from "swr";

export default function useUser() {
  const { data, error } = useSWR(`/user`);

  if (data && error?.status === 401) {
    return {
      user: null,
      isLoading: false,
      isError: error,
    };
  }
  return {
    user: data?.admin,
    isLoading: !error && !data,
    isError: error,
  };
}
