import { Button, Card, Text, TextInput } from "@mantine/core";
import { IconPlus, IconSearch } from "@tabler/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import SWRrender from "../../components/SWRrender";
import MainLayout from "../../layouts/MainLayout";
import ZonesModal from "./components/ZonesModal";
import ZonesTable from "./components/ZonesTable";
import styles from "./styles";

const Zones = () => {
  const { classes } = styles();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [zone, setZone] = useState(null);

  const onZoneClick = (zone: any) => {
    setZone(zone);
    setShowModal(true);
  };
  return (
    <MainLayout>
      <Card>
        <Card.Section p="md">
          <Text size={"md"}>Listado de sectores</Text>
        </Card.Section>
        <Card.Section p="md">
          <div className={classes.header}>
            <div>
              <Button onClick={() => setShowModal(true)}>
                <IconPlus />
              </Button>
            </div>

            <div>
              <TextInput
                width={"100%"}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Buscar sectores"
                icon={<IconSearch />}
              />
            </div>
          </div>
        </Card.Section>
        <SWRrender>
          <RenderZonesTable search={search} onZoneClick={onZoneClick} />
        </SWRrender>
      </Card>
      <ZonesModal
        zone={zone}
        setZone={setZone}
        opened={showModal}
        setOpened={setShowModal}
      />
    </MainLayout>
  );
};

const RenderZonesTable = (props: any) => {
  const { search, onZoneClick } = props;
  const {
    data: { zones },
  } = useSWR("/zones", { suspense: true });
  return <ZonesTable data={zones} search={search} onClick={onZoneClick} />;
};

export default Zones;
