import { Button, Modal, MultiSelect, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useEffect } from "react";
import useSWR, { mutate } from "swr";
import api from "../../../../api";
import SWRrender from "../../../../components/SWRrender";
import { showErrorToast, showSuccessToast } from "../../../../utils/toasts";
import * as Yup from "yup";

const ZonesModal = (props: any) => {
  const { opened, setOpened, zone, setZone } = props;

  const form = useForm({
    initialValues: {
      name: "",
      description: "",
      responsibles_id: [],
    },
    validate: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (zone && opened) {
      form.setValues(zone);
    } else if (!zone && !opened) {
      form.reset();
      setZone(null);
    } else if (!opened) {
      form.reset();
      setZone(null);
    }
  }, [zone, opened]);

  const create = (values: any) => {
    api
      .post("/zones", values)
      .then((res) => {
        if (res.data.ok) {
          setOpened(false);
          showSuccessToast({
            message: "Sector creado correctamente",
          });
          mutate("/zones");
        }
      })
      .catch((err) => {
        showErrorToast({
          message: "Error al crear el sector",
        });
      });
  };

  const update = (values: any) => {
    api
      .put(`/zones/${zone.id}`, values)
      .then((res) => {
        if (res.data.ok) {
          setOpened(false);
          showSuccessToast({
            message: "Sector actualizado correctamente",
          });
          mutate("/zones");
        }
      })
      .catch((err) => {
        showErrorToast({
          message: "Error al actualizar el sector",
        });
      });
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={zone ? "Editar sector" : "Crear sector"}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            if (zone) {
              update(values);
            } else {
              create(values);
            }
          })}
        >
          <TextInput
            name="name"
            label="Nombre del sector"
            {...form.getInputProps("name")}
            required
          />

          <TextInput
            name="description"
            label="Descripción del sector"
            {...form.getInputProps("description")}
            mt="sm"
          />

          <SWRrender>
            <ResponsibleSelect form={form} />
          </SWRrender>

          <Button type="submit" fullWidth mt="sm">
            {zone ? "Actualizar" : "Crear"} sector
          </Button>
        </form>
      </Modal>
    </>
  );
};

const ResponsibleSelect = (props: any) => {
  const { form } = props;
  const {
    data: { users },
  } = useSWR("/users", { suspense: true });
  const responsibles = users.map((user: any) => ({
    label: user.name,
    value: user.id,
  }));
  return (
    <MultiSelect
      label="Personas responsables"
      name="responsibles_id"
      data={responsibles}
      {...form.getInputProps("responsibles_id")}
      mt="sm"
    />
  );
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
  description: Yup.string().nullable(),
  responsibles_id: Yup.array().nullable(),
});

export default ZonesModal;
