import BaseTable from "../../../../components/BaseTable";

const columns = [
  {
    Header: "Nombre y Apellidos",
    accessor: "name",
  },
  {
    Header: "Correo electronico",
    accessor: "email",
  },
  {
    Header: "Telefono",
    accessor: "phone",
  },
  {
    Header: "Sector",
    accessor: "sector",
  },
];

const VolunteersTable = (props: any) => {
  const { data, search } = props;

  return (
    <BaseTable
      data={data}
      columns={columns}
      search={search}
      redirectRoute="/volunteers/<id>"
    />
  );
};

export default VolunteersTable;
