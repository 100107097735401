import {
  TextInput,
  PasswordInput,
  Paper,
  Container,
  Button,
  Center,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import api from "../../api";
import { showErrorToast } from "../../utils/toasts";
import { saveToken } from "../../utils/token";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";

const LoginPage = () => {
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: yupResolver(validateSchema),
  });

  const navigate = useNavigate();

  return (
    <div
      style={{
        minHeight: "98vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container size={"sm"} my={40} style={{ width: "100%" }}>
        <Center>
          <img width={"60%"} src="/logo.png" />
        </Center>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <form
            onSubmit={form.onSubmit((values) => {
              api
                .post("/login_admin", { ...values })
                .then((data) => {
                  console.log(data);
                  if (data.data.token) {
                    saveToken(data.data.token);
                    mutate("/user", null, true);
                    navigate("/");
                  }
                })
                .catch((err) => {
                  showErrorToast({
                    message: "Correo electronico o contraseña incorrectos",
                  });
                });
            })}
          >
            <TextInput
              label="Correo electronico"
              placeholder="test@example.com"
              required
              name="email"
              {...form.getInputProps("email")}
            />
            <PasswordInput
              label="Contraseña"
              placeholder="********"
              required
              name="password"
              mt="md"
              {...form.getInputProps("password")}
            />

            <Button fullWidth mt="xl" type="submit">
              Iniciar sesión
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

const validateSchema = Yup.object().shape({
  email: Yup.string()
    .email("Correo electronico invalido")
    .required("Este campo es requerido"),
  password: Yup.string().required("Este campo es requerido"),
});

export default LoginPage;
