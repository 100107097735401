import { Card, Grid, Text } from "@mantine/core";
import MainLayout from "../../../layouts/MainLayout";
import VolunteerForm from "../components/VolunteerForm";

const NewVolunteer = () => {
  return (
    <MainLayout>
      <Card>
        <Card.Section p="md">
          <Text size={"md"}>Nuevo voluntario</Text>
        </Card.Section>
        <VolunteerForm />
      </Card>
    </MainLayout>
  );
};

export default NewVolunteer;
