import { Box, Pagination, ScrollArea, Table } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePagination, useTable } from "react-table";
import styles from "./styles";

const BaseTable = (props) => {
  const { columns, data, search = null, redirectRoute = null, onClick } = props;
  const { classes } = styles();
  const [filteredData, setFilteredData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (search) {
      const filteredData = data.filter((row) => {
        return Object.values(row).some((cell) => {
          return String(cell).toLowerCase().includes(search.toLowerCase());
        });
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData(data);
    }
  }, [search, data]);

  const tableInstance = useTable(
    { columns, data: filteredData, initialState: { pageIndex: 0 } },
    usePagination
  );

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const handlePageChange = (e) => {
    gotoPage(e - 1);
  };

  const handleRedirect = (row) => {
    if (redirectRoute) {
      const route = redirectRoute.replace("<id>", row.original.id);

      navigate(route);
    } else if (onClick) {
      onClick(row.original);
    }
  };

  return (
    <>
      <ScrollArea>
        <Table sx={{ minWidth: 800 }} verticalSpacing="sm" {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()}>
                        {
                          // Render the header
                          column.render("Header")
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    {...row.getRowProps()}
                    onClick={() => handleRedirect(row)}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </ScrollArea>
      <Box className={classes.pagination}>
        <Pagination
          page={pageIndex + 1}
          onChange={handlePageChange}
          total={pageCount}
          mt="sm"
        />
      </Box>
    </>
  );
};

export default BaseTable;
