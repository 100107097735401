import { Card, Title } from "@mantine/core";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import SWRrender from "../../../components/SWRrender";
import MainLayout from "../../../layouts/MainLayout";
import VolunteerForm from "../components/VolunteerForm";

const UpdateVolunteers = () => {
  const { id } = useParams();

  return (
    <MainLayout>
      <SWRrender>
        <RenderUpdateForm id={id} />
      </SWRrender>
    </MainLayout>
  );
};

const RenderUpdateForm = (props: any) => {
  const { id } = props;

  const {
    data: { volunteer },
  } = useSWR(`/volunteers/${id}`, { suspense: true });
  return (
    <Card>
      <Card.Section p="md">
        <Title order={4}>Actualizar voluntario</Title>
      </Card.Section>
      <VolunteerForm volunteer={volunteer} />
    </Card>
  );
};

export default UpdateVolunteers;
