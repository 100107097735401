import { Button, Card, Text, TextInput } from "@mantine/core";
import { IconPlus, IconSearch } from "@tabler/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import SWRrender from "../../components/SWRrender";
import MainLayout from "../../layouts/MainLayout";
import VolunteersTable from "./components/VolunteersTable";
import styles from "./styles";

const Volunteers = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { classes } = styles();

  return (
    <MainLayout>
      <Card>
        <Card.Section p="md">
          <Text size={"md"}>Listado de voluntarios</Text>
        </Card.Section>
        <Card.Section p="md">
          <div className={classes.header}>
            <div>
              <Button onClick={() => navigate("/volunteers/new")}>
                <IconPlus />
              </Button>
            </div>

            <div>
              <TextInput
                width={"100%"}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Buscar voluntario"
                icon={<IconSearch />}
              />
            </div>
          </div>
        </Card.Section>
        <SWRrender>
          <RenderVolunteersTable search={search} />
        </SWRrender>
      </Card>
    </MainLayout>
  );
};

const RenderVolunteersTable = (props: any) => {
  const { search } = props;
  const {
    data: { volunteers },
  } = useSWR("/volunteers", { suspense: true });
  return <VolunteersTable data={volunteers} search={search} />;
};
export default Volunteers;
