import * as React from "react";
import { Routes, Route, Link, Outlet, Navigate } from "react-router-dom";
import useUser from "./hooks/useUser";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Volunteers from "./pages/Volunteers";
import NewVolunteer from "./pages/Volunteers/New/NewVolunteer";
import UpdateVolunteers from "./pages/Volunteers/Update/Update";
import Zones from "./pages/Zones";

const ProtectedRoute = ({ user, redirectPath = "/login" }: any) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

function Router() {
  const { user, isLoading } = useUser();

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="App">
      <Routes>
        <Route element={<ProtectedRoute user={user} />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/volunteers" element={<Volunteers />} />
          <Route path="/volunteers/:id" element={<UpdateVolunteers />} />
          <Route path="/volunteers/new" element={<NewVolunteer />} />
          <Route path="/zones" element={<Zones />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default Router;
