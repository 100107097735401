import {
  Box,
  Button,
  Group,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { DatePicker } from "@mantine/dates";
import * as yup from "yup";
import { useEffect } from "react";
import api from "../../../../api";
import { showErrorToast, showSuccessToast } from "../../../../utils/toasts";

const VolunteerForm = (props: any) => {
  const { volunteer } = props;

  const form = useForm({
    initialValues: {
      document: "",
      name: "",
      surname: "",
      phone: "",
      email: "",
      birth_date: "",
      has_vehicle: "",
      shirt_size: "",
      observations: "",
      type: "",
      organization: "",
      previously_participated: "",
      food_intolerances: "",
    },
    validate: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (volunteer) {
      form.setValues({
        ...volunteer,
        birth_date: new Date(volunteer.birth_date),
      });
    } else {
      form.reset();
    }
  }, [volunteer]);

  const prepareRequest = (values: any) => {
    const request = {
      ...values,
      birth_date: values.birth_date.toISOString().split("T")[0],
      has_vehicle: values.has_vehicle === "true" ? 1 : 0,
      previously_participated:
        values.previously_participated === "true" ? 1 : 0,
    };
    return request;
  };

  const calcAge = (dateString: any) => {
    var birthday = +new Date(dateString);
    return ~~((Date.now() - birthday) / 31557600000);
  };

  const create = (values: any) => {
    const autorization = calcAge(values.birth_date) >= 18 ? true : false;

    let auto: any = {};
    if (autorization) {
      auto.docuemnt = values.menor_document;
      auto.name = values.menor_name;
      auto.surname = values.menor_surname;
      auto.phone = values.menor_phone;
    }

    api
      .post("/volunteers", {
        volunteer: {
          ...prepareRequest(form.values),
        },
        accreditation: { ...auto },
      })
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({
            message: "Voluntario creado correctamente",
          });
        }
      })
      .catch((err) => {
        showErrorToast({
          message: "Error al crear el voluntario",
        });
      });
  };

  const update = (values: any) => {
    const autorization = calcAge(values.birth_date) >= 18 ? true : false;

    let auto: any = {};
    if (autorization) {
      auto.docuemnt = values.menor_document;
      auto.name = values.menor_name;
      auto.surname = values.menor_surname;
      auto.phone = values.menor_phone;
    }
    api
      .put(`/volunteers/${volunteer.id}`, {
        volunteer: { ...prepareRequest(form.values) },
        accreditation: { ...auto },
      })
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({
            message: "Voluntario actualizado correctamente",
          });
        }
      })
      .catch((err) => {
        showErrorToast({
          message: "Error al actualizar el voluntario",
        });
      });
  };
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        if (volunteer) {
          update(values);
        } else {
          create(values);
        }
      })}
    >
      <TextInput
        name="document"
        label="Documento"
        placeholder="Documento"
        {...form.getInputProps("document")}
        required
      />

      <TextInput
        name="name"
        label="Nombre"
        placeholder="Nombre"
        {...form.getInputProps("name")}
        required
        mt={"sm"}
      />

      <TextInput
        name="surname"
        label="Apellidos"
        placeholder="Apellidos"
        {...form.getInputProps("surname")}
        required
        mt={"sm"}
      />

      <TextInput
        name="email"
        label="Correo electronico"
        placeholder="Correo electronico"
        {...form.getInputProps("email")}
        required
        mt={"sm"}
      />

      <TextInput
        name="phone"
        label="Telefono"
        placeholder="Telefono"
        {...form.getInputProps("phone")}
        required
        mt={"sm"}
      />

      <DatePicker
        name="birth_date"
        label="Fecha de nacimiento"
        placeholder="Fecha de nacimiento"
        {...form.getInputProps("birth_date")}
        inputFormat="DD/MM/YYYY"
        allowFreeInput
        required
        mt={"sm"}
      />

      {calcAge(form.values.birth_date) < 18 && form.values.birth_date && (
        <Box ml="sm" mt="sm">
          <Text>Datos de la autorización para menores de edad</Text>
          <TextInput
            name="menor_document"
            label="Documento del tutor"
            placeholder="Documento del tutor"
            {...form.getInputProps("menor_document")}
            required
          />
          <TextInput
            name="menor_name"
            label="Nombre del tutor"
            placeholder="Nombre del tutor"
            {...form.getInputProps("menor_name")}
            required
            mt={"sm"}
          />
          <TextInput
            name="menor_surname"
            label="Apellidos del tutor"
            placeholder="Apellidos del tutor"
            {...form.getInputProps("menor_surname")}
            required
            mt={"sm"}
          />

          <TextInput
            name="menor_phone"
            label="Telefono del tutor"
            placeholder="Telefono del tutor"
            {...form.getInputProps("menor_phone")}
            required
            mt={"sm"}
          />
        </Box>
      )}

      <Select
        name="has_vehicle"
        label="¿Tienes vehiculo propio?"
        placeholder="Selecciona una opcion"
        {...form.getInputProps("has_vehicle")}
        value={form.values.has_vehicle.toString()}
        data={[
          {
            label: "Si",
            value: "true",
          },
          {
            label: "No",
            value: "false",
          },
        ]}
        required
        mt={"sm"}
      />

      <Select
        name="shirt_size"
        label="Talla de camiseta"
        placeholder="Selecciona una opcion"
        {...form.getInputProps("shirt_size")}
        data={[
          {
            label: "S",
            value: "S",
          },
          {
            label: "M",
            value: "M",
          },
          {
            label: "L",
            value: "L",
          },
          {
            label: "XL",
            value: "XL",
          },
          {
            label: "XXL",
            value: "XXL",
          },
        ]}
        required
        mt={"sm"}
      />

      <Textarea
        name="observations"
        label="Observaciones"
        placeholder="Observaciones"
        {...form.getInputProps("observations")}
        mt={"sm"}
      />

      <Select
        name="type"
        label="Tipo de voluntario"
        placeholder="Selecciona una opcion"
        {...form.getInputProps("type")}
        data={[
          {
            label: "Voluntario",
            value: "Voluntario",
          },
          {
            label: "Fotógrafo",
            value: "Fotógrafo",
          },
          {
            label: "Prensa",
            value: "Prensa",
          },
          {
            label: "Patrocinador",
            value: "Patrocinador",
          },
          {
            label: "Organización",
            value: "Organización",
          },
        ]}
        required
        mt={"sm"}
      />

      <TextInput
        name="organization"
        label="Organización a la que perteneces"
        placeholder="Organización a la que perteneces"
        {...form.getInputProps("organization")}
        mt={"sm"}
      />

      <Select
        name="previously_participated"
        label="¿Has sido voluntatio en la transilicitana?"
        placeholder="Selecciona una opcion"
        {...form.getInputProps("previously_participated")}
        value={form.values.previously_participated.toString()}
        data={[
          {
            label: "Si",
            value: "true",
          },
          {
            label: "No",
            value: "false",
          },
        ]}
        required
        mt={"sm"}
      />

      <TextInput
        name="food_intolerances"
        label="Intolerancias alimenticias"
        placeholder="Intolerancias alimenticias"
        {...form.getInputProps("food_intolerances")}
        mt={"sm"}
      />

      <Button mt={"sm"} type="submit" fullWidth>
        {volunteer ? "Actualizar voluntario" : "Crear voluntario"}
      </Button>
    </form>
  );
};

const validationSchema = yup.object().shape({
  document: yup.string().required("Documento es requerido"),
  name: yup.string().required("Nombre es requerido"),
  surname: yup.string().required("Apellidos es requerido"),
  email: yup.string().required("Correo electronico es requerido"),
  phone: yup.string().required("Telefono es requerido"),
  birth_date: yup.string().required("Fecha de nacimiento es requerido"),
  has_vehicle: yup.string().required("¿Tienes vehiculo propio? es requerido"),
  shirt_size: yup.string().required("Talla de camiseta es requerido"),
  type: yup.string().required("Tipo de voluntario es requerido"),
  previously_participated: yup
    .string()
    .required("¿Has sido voluntatio en la transilicitana? es requerido"),
});

export default VolunteerForm;
