import BaseTable from "../../../../components/BaseTable";

const columns = [
  {
    Header: "Nombre del sector",
    accessor: "name",
  },
  {
    Header: "Descripción del sector",
    accessor: "description",
  },
  {
    Header: "Responsables",
    accessor: "responsibles",
    Cell: (props: any) => {
      const { value } = props;
      return value.map((responsible: any) => (
        <div key={responsible.id}>{responsible.name}</div>
      ));
    },
  },
];

const ZonesTable = (props: any) => {
  return <BaseTable columns={columns} {...props} />;
};

export default ZonesTable;
